<template>
        <div v-if='JSON.stringify(page) != "{}"' class="content">
            <div v-if="page.showContentOnly">
                <MyBreads>{{page.title}}</MyBreads>
                <el-row type="flex" justify="start" style="flex-wrap: wrap">
                    <el-col :lg="24" :md="24" :sm="24" :xs="24">
                        <el-card style="margin: 15px 0px;" shadow="hover">
                            <div class="el-card__body" style="display: flex;flex-direction:column;align-items: start">
                                <p v-html="page.content"></p>
                                <p class="store-item"></p>
                            </div>
                        </el-card>
                    </el-col>
                </el-row>
            </div>
            <div v-else>
                <div class="sub-title">
                    <span><a href="/">HOME</a></span>
                    <span>/</span>
                    <span>文章資訊</span>
                </div>
                <el-row type="flex" justify="start" style="flex-wrap: wrap">
                    <el-col>
                        <el-card style="margin: 10px;" shadow="hover">
                            <div class="el-card__header">
                                <p style="font-size: 20px;text-align: center">{{page.title}}</p>
                                <p v-if="page.titleNext" style="color: #54595F;font-size: small;margin-left: 10px">({{page.titleNext}})</p>
                                <p style="text-align: center;">作者:{{page.author}}</p>
                            </div>
                            <div class="el-card__body" style="display: flex;flex-direction:column;align-items: start">
                                <h5>簡介:{{page.synopsis}}</h5>
                                <p v-html="page.content"></p>
                                <p class="store-item"></p>
                            </div>
                        </el-card>
                    </el-col>
                </el-row>
            </div>
        </div>
        <div class="content" v-else>
            <el-row>
                <el-col>
                    <el-card style="margin: 10px;" shadow="hover">
                        <div class="el-card__header">
                        </div>
                        <div class="el-card__body" style="display: flex;justify-content: center">
                            <el-empty :image-size="100" description="暫時沒有資料">
                            </el-empty>
                        </div>
                    </el-card>
                </el-col>
            </el-row>
        </div>
</template>

<script>
    import {GetArticleInfoById} from '@/request/api';
    import MyBreads from '@/components/MyBreads.vue';

    export default {
        name: '',
        components: {MyBreads},
        data() {
            return {
                page: {},
                emptFlg: false
            }
        },
        methods: {
            getArticleInfoById(id) {
                if (id == "") {
                    return;
                }
                let _this = this;
                GetArticleInfoById(id).then(res => {
                    if (res.code == 200) {
                        _this.page = res.data;
                    } else {
                        this.$message.error(res.message);
                    }
                }).catch(err => {
                    console.log(err);
                    this.$message.error('網路連線失敗，稍候請重試!');
                });
            },
        }, mounted() {
            if (this.$route.params.id) {
                this.getArticleInfoById(this.$route.params.id);
            }
        }
    }
</script>
<style lang="less" scoped>
    .store-item {
        margin: 10px 1%;
        padding-bottom: 10px;

        &:last-child {
            border-bottom-width: 0px;
            margin-bottom: 0px;
        }
    }

    .sub-title {
        display: flex;
        justify-content: start;

        span {
            margin-right: 10px;

            a {
                text-decoration: none;
            }
        }
    }
</style>
